import React, { useState } from 'react';
import { AboutField } from 'plugin-ui-components';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { MarkdownContent } from '@backstage/core-components';

export interface AboutFieldWithDialogProps {
  label: string;
  model: any;
}

const AboutFieldWithDialog = ({ label, model }: AboutFieldWithDialogProps) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const description = model.description as string;
  const wordLimit = 100;
  const descriptionWords = description ? description.split(' ') : [];

  return (
    <AboutField label="Description" xs={12}>
      <Box display="flex" flexDirection="column">
        <Typography variant="subtitle1">
          <MarkdownContent
            content={
              descriptionWords.length <= wordLimit
                ? description
                : `${descriptionWords.slice(0, wordLimit).join(' ')}... `
            }
          />
          {descriptionWords.length > wordLimit && (
            <Typography
              component="span"
              variant="body2"
              color="primary"
              style={{ cursor: 'pointer' }}
              onClick={handleOpen}
            >
              Read all
            </Typography>
          )}
        </Typography>
      </Box>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>{label}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            <MarkdownContent content={description} />
          </Typography>
        </DialogContent>
      </Dialog>
    </AboutField>
  );
};

export default AboutFieldWithDialog;
