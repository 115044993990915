import { createTimeColumn } from '../common';
import React, { useMemo } from 'react';
import { createDescriptionColumn } from '../Models/common';
import { TableColumn } from '@backstage/core-components';
import { ModelVersion } from '../../../../api/interfaces/model_version';
import { Box, Tooltip } from '@material-ui/core';
import { SpacedTagGroup } from '../../../../styles/common';
import * as S from '../../../../styles/table';
import { TextTag, TextWithLinkTag } from '../Tags';
import { colors } from 'plugin-ui-components';

export function createModelVersionNameColumn(): TableColumn<ModelVersion> {
  return {
    title: 'Name',
    field: 'name',
    sorting: true,
    render: (model_version: ModelVersion) => {
      return (
        <Box flex={1} margin={0} padding={0}>
          <div key={model_version.model_version_id}>
            <Box pt={2} pr={2} pl={1} key="model-item">
              <SpacedTagGroup>
                <Tooltip title="Model name">
                  <S.Title
                    to={`/ml-platform-ui/models/${model_version.model_id}/versions/${model_version.model_version_id}`}
                  >
                    {model_version.name}
                  </S.Title>
                </Tooltip>
              </SpacedTagGroup>
              <SpacedTagGroup>
                <TextTag text={model_version.stage} title="Stage" />
                <TextTag
                  text={model_version.origin}
                  title="Origin"
                  color={colors.semantic.infoDark}
                />
                <TextWithLinkTag
                  text={model_version.source_type as string}
                  link={model_version.source_uri as string}
                  title="Source"
                />
              </SpacedTagGroup>
            </Box>
          </div>
        </Box>
      );
    },
  };
}

export const useModelVersionColumns = () => {
  return useMemo(() => {
    return [
      createModelVersionNameColumn(),
      createTimeColumn(),
      createDescriptionColumn(),
    ];
  }, []);
};
