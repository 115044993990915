import React, { useState } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import { LicenseType } from '../../api/interfaces/model';
import { EnumFieldFilter } from '../tables/ResultsFilter/EnumFilter';
import { SPECIAL_CHARACTERS } from '../../constants/paging';

interface EditModelFormProps {
  form: {
    license_type: string | undefined;
    name: string;
    description: string | undefined;
    license_link: string | undefined;
  };
  updatingModel: boolean;
  setChangeMade: (value: ((prevState: boolean) => boolean) | boolean) => void;
  setHasError: (value: ((prevState: boolean) => boolean) | boolean) => void;
  setForm: (
    value:
      | ((prevState: {
          license_link: string | undefined;
          license_type: string | undefined;
          name: string;
          description: string | undefined;
        }) => {
          license_link: string | undefined;
          license_type: string | undefined;
          name: string;
          description: string | undefined;
        })
      | {
          license_link: string | undefined;
          license_type: string | undefined;
          name: string;
          description: string | undefined;
        },
  ) => void;
}

export const EditModelForm = ({
  setForm,
  form,
  updatingModel,
  setChangeMade,
  setHasError,
}: EditModelFormProps) => {
  const [errors, setErrors] = useState<{
    name?: string;
    licenseLink?: string;
    description?: string;
  }>({});
  const [modelNameLength, setModelNameLength] = useState<number>(0);

  const validateName = (name: string) => {
    if (name.length < 4 || SPECIAL_CHARACTERS.test(name)) {
      return 'The model name must have at least 4 characters and not contain special characters other than _ and -.';
    }
    return '';
  };

  const handleNameChange = (field: string, ev: any) => {
    const value = ev.target.value;

    if (form.name === value) return;
    setForm(prevState => ({ ...prevState, [field]: value }));
    if (field === 'name') {
      setModelNameLength(value.length);
      const nameError = validateName(value);
      setErrors(prev => ({ ...prev, name: nameError }));
      setHasError(nameError.length > 0);
    }
    setChangeMade(true);
  };

  const handleLicenseLinkChange = (ev: any) => {
    const value = ev.target.value;

    if (form.license_link === value) return;
    setForm(prevState => ({ ...prevState, license_link: value }));
    if (!value.startsWith('http')) {
      setErrors(prev => ({
        ...prev,
        licenseLink: 'License link must start with "http".',
      }));
      setHasError(true);
    } else {
      setErrors(prev => ({ ...prev, license_link: '' }));
      setChangeMade(true);
    }
  };

  const handleDescriptionChange = (ev: any) => {
    const value = ev.target.value;

    if (form.description === value) return;
    setForm(prevState => ({ ...prevState, description: value }));
    if (value.length < 10) {
      setErrors(prev => ({
        ...prev,
        description: 'Description must be at least 10 characters long.',
      }));
      setHasError(true);
    } else {
      setErrors(prev => ({ ...prev, description: '' }));
      setChangeMade(true);
    }
  };

  const handleLicenseChange = (value: string) => {
    if (form.license_type === value) return;
    setForm(prev => ({ ...prev, license_type: value }));
    setChangeMade(true);
  };

  return (
    <Box display="flex" flexDirection="column" gap="1rem">
      <form>
        <FormControl fullWidth size="medium">
          <TextField
            value={form.name}
            onChange={ev => handleNameChange('name', ev)}
            fullWidth
            error={!!errors.name}
            helperText={errors.name || `Current Length: ${modelNameLength}`}
            disabled={updatingModel}
            size="small"
            required
          />
        </FormControl>

        <FormControl fullWidth size="small">
          <EnumFieldFilter
            label="License"
            id="license"
            enumField={LicenseType}
            selected={form.license_type || ''}
            onChange={value => handleLicenseChange(value)}
            loading={updatingModel}
          />
          <FormHelperText>
            Select the appropriate license for the model.
          </FormHelperText>
        </FormControl>

        <FormControl fullWidth size="medium">
          <TextField
            value={form.license_link}
            onChange={handleLicenseLinkChange}
            fullWidth
            error={!!errors.licenseLink}
            helperText={errors.licenseLink || 'The link to the license'}
            disabled={updatingModel}
            size="small"
            required
          />
        </FormControl>

        <FormControl fullWidth size="medium">
          <TextField
            value={form.description}
            onChange={handleDescriptionChange}
            fullWidth
            multiline
            rows={5}
            error={!!errors.description}
            helperText={
              errors.description ||
              'A description of the model. You can be as detailed as you want.'
            }
            disabled={updatingModel}
            size="medium"
            required
          />
        </FormControl>
      </form>
    </Box>
  );
};
