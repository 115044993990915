import React from 'react';
import { Model } from '../../../../api/interfaces/model';
import { Tooltip, Typography } from '@material-ui/core';
import * as S from '../../../../styles/table';
import { ApplicationTag, TextWithLinkTag, UserTag } from '../Tags';
import { SpacedTagGroup } from '../../../../styles/common';
import { TableColumn } from '@backstage/core-components';
import Box from '@mui/material/Box';

export function createNameColumn(): TableColumn<Model> {
  return {
    title: 'Name',
    field: 'name',
    sorting: true,
    filtering: false,
    render: (model: Model) => {
      return (
        <Box flex={1} margin={0} padding={0}>
          <div key={model.model_id}>
            <Box pt={2} pr={2} pl={1} key="model-item">
              <>
                <Tooltip title="Model name">
                  <S.Title to={`/mlp/models/${model.model_id}`}>
                    {model.name}
                  </S.Title>
                </Tooltip>
              </>
              <SpacedTagGroup>
                <ApplicationTag name={model.application_id} />
                <UserTag user={model.created_by.uid} />
                <TextWithLinkTag
                  text={model.license_type as string}
                  link={model.license_link as string}
                  title="License Type"
                />
              </SpacedTagGroup>
            </Box>
          </div>
        </Box>
      );
    },
  };
}

export function createDescriptionColumn(): TableColumn<Model> {
  return {
    title: 'Description',
    field: 'description',
    filtering: false,
    sorting: false,
    render: (model: Model) => {
      return (
        <Box flex={1} margin={0} padding={0}>
          <div key={model.model_id} style={{ width: '100%' }}>
            <Tooltip title={model.description as string}>
              <Typography
                variant="subtitle2"
                style={{
                  fontSize: '0.75rem',
                }}
                noWrap={false}
              >
                {`${model.description?.substring(0, 200)}...`}
              </Typography>
            </Tooltip>
          </div>
        </Box>
      );
    },
  };
}
