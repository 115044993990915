import React, { useEffect, useState } from 'react';
import { Model } from '../../../api/interfaces/model';
import { InfoCard } from '@backstage/core-components';
import {
  AboutField,
  TeamReference,
  useUserTeams,
  useUserTeamsApps,
} from 'plugin-ui-components';
import {
  ApplicationTag,
  TextWithLinkTag,
  UserTag,
} from '../../tables/columns/Tags';
import {
  Box,
  Button,
  CardContent,
  Grid,
  LinearProgress,
} from '@material-ui/core';
import Stack from '@mui/material/Stack';
import { EditDialog } from './EditDialog';
import AboutFieldWithDialog from './AbouFieldWithDialog';

export interface BasicDetailsProps {
  model: Model;
  loading?: boolean;
  versionCount: number;
}

export const BasicDetails = ({
  model,
  versionCount,
  loading = false,
}: BasicDetailsProps) => {
  const {
    value: { userTeams = [] },
  } = useUserTeams();
  const { value: userApps = [] } = useUserTeamsApps();

  const modelOwnersTeams = userTeams.filter(team =>
    userApps.some(
      app =>
        app.metadata.name === model.application_id &&
        app.spec.spec.team_id === team.spec.id,
    ),
  );
  const canEdit = modelOwnersTeams.length > 0;

  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  const [updatedModel, setUpdatedModel] = useState<boolean>(false);

  const handleEditDialogToggle = () => {
    setEditDialogOpen(prev => !prev);
  };

  useEffect(() => {
    if (updatedModel) {
      window.location.reload();
      setUpdatedModel(false);
    }
  }, [updatedModel]);

  return (
    <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InfoCard
            title="Overview"
            action={
              <Stack spacing={1} direction="row">
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  onClick={handleEditDialogToggle}
                  disabled={!canEdit}
                >
                  Edit
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  disabled
                >
                  View Model Card
                </Button>
              </Stack>
            }
          >
            <CardContent>
              {loading ? (
                <LinearProgress />
              ) : (
                <Grid container>
                  <AboutField
                    label="Model Name"
                    children={model.name}
                    xs={3}
                    canCopy
                  />
                  <AboutField
                    label="Created at"
                    children={model.created_at}
                    xs={3}
                  />
                  {model.updated_at && (
                    <AboutField
                      label="Updated at"
                      children={model.updated_at}
                      xs={3}
                    />
                  )}
                  <AboutField label="Owning Team" xs={3}>
                    {modelOwnersTeams.map(team => (
                      <TeamReference
                        key={team.metadata.name}
                        team={team as IEntityGroup}
                      />
                    ))}
                  </AboutField>
                  <AboutField
                    label="Created By"
                    children={<UserTag user={model.created_by.uid} />}
                    xs={3}
                  />
                  <AboutField label="License" xs={3}>
                    <TextWithLinkTag
                      text={model.license_type as string}
                      link={model.license_link as string}
                      title="License"
                    />
                  </AboutField>
                  <AboutField
                    label="Application"
                    children={<ApplicationTag name={model.application_id} />}
                    xs={3}
                  />
                  <AboutField
                    label="Version Count"
                    children={`${versionCount}`}
                    xs={3}
                  />
                  <AboutFieldWithDialog label="Description" model={model} />
                </Grid>
              )}
            </CardContent>
          </InfoCard>
          <EditDialog
            open={isEditDialogOpen}
            model_id={model.model_id}
            handleClose={handleEditDialogToggle}
            name={model.name}
            description={model.description}
            license={model.license_type}
            setUpdatedModel={setUpdatedModel}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
