import React, { useEffect, useState } from 'react';
import { useUserTeamsApps } from 'plugin-ui-components';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { getQueryValue, SEARCH_DEBOUNCE_TIME } from 'plugin-core';
import { Model } from '../../api/interfaces/model';
import { modelsApiRef } from '../../api/services/models';
import { useApplicationIDSelection } from '../../hooks/useApplicationIdSelection';
import { DEFAULT_PAGE_SIZE } from '../../constants/paging';
import { CatalogMeta } from '../../api/interfaces/meta';
import { Grid } from '@material-ui/core';
import { Content } from '@backstage/core-components';
import { GroupFilters } from '../tables/GroupFilters';
import { getMLFilterGroups } from '../tables/FilterGroups';
import { ResourceFilters } from '../tables/ResourceFilters';
import { ResourceTable } from '../tables/ResourceTable';
import { useModelColumns } from '../tables/columns/Models';
import { useDebounce } from 'react-use';
import { ModelFilterStore } from '../../api/interfaces/store';

interface ModelsPageProps {
  setModelFilters?: (
    value:
      | ((
          prevState: ModelFilterStore | undefined,
        ) => ModelFilterStore | undefined)
      | ModelFilterStore
      | undefined,
  ) => void;
  modelFilters?: ModelFilterStore | undefined;
}

const getCountForUserResources = (models: Model[], userApps: IEntityApp[]) => {
  return models.filter(model =>
    userApps.some(app => app.metadata.name === model.application_id),
  ).length;
};

const appsPlaceholder: IEntityApp[] = [];

export const Models: React.FC<ModelsPageProps> = ({
  modelFilters,
  setModelFilters,
}: ModelsPageProps) => {
  const configApi = useApi(configApiRef);
  const modelsApi = useApi(modelsApiRef);
  const [models, setModels] = useState<Model[]>([]);
  const [meta, setMeta] = useState<CatalogMeta>();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [currentPageSize, setCurrentPageSize] =
    useState<number>(DEFAULT_PAGE_SIZE);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [itemCount, setItemCount] = useState<{ mine: number; all: number }>({
    mine: 0,
    all: 0,
  });
  const orgName = configApi.getOptionalString('organization.name') ?? 'Company';
  const defaultSelectedFilter = getQueryValue('group') || 'mine';
  const [selectedSidebarItem, setSelectedSidebarItem] = useState<{
    id: string;
    label: string;
  }>();
  const filterGroups = React.useMemo(
    () => getMLFilterGroups('models', orgName, itemCount),
    [itemCount, orgName],
  );

  const { value: userApps = appsPlaceholder } = useUserTeamsApps();

  const applicationIDSelection = useApplicationIDSelection(
    defaultSelectedFilter,
    userApps,
    modelFilters,
  );

  useDebounce(
    () => {
      const controller = new AbortController();
      setModels([]);

      async function fetchInitialModels() {
        const data = await fetch();
        setModels(data);
      }
      fetchInitialModels();
      return () => controller.abort();
    },
    SEARCH_DEBOUNCE_TIME,
    [modelFilters],
  );

  async function fetch(offset: number = 0): Promise<Model[]> {
    const queryParameters = {
      application_id: applicationIDSelection(),
      limit: DEFAULT_PAGE_SIZE,
      tag_name: modelFilters?.tag_name || '',
      account: modelFilters?.account || '',
      license_type: modelFilters?.license || '',
      name: modelFilters?.name || '',
      offset: offset,
    };

    let data: Model[] = [];
    setLoading(true);
    setError(null);

    try {
      const response = await modelsApi.getModels(queryParameters);
      data = response.models || [];
      setMeta(response.meta);
    } catch (e) {
      const err = e as Error;
      setError(err);
    }
    setLoading(false);
    return data;
  }

  useEffect(() => {
    setItemCount({
      mine: getCountForUserResources(models, userApps),
      all: meta?.totals.models as number,
    });
  }, [meta?.totals.models, models, userApps]);

  async function handleNextPage() {
    const newModels = await fetch(currentPageSize);
    setModels([...models, ...newModels]);
    setCurrentPageSize(currentPageSize + DEFAULT_PAGE_SIZE);
    setHasNextPage(itemCount.all > currentPageSize);
  }

  const modelColumns = useModelColumns();

  return (
    <Content>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <GroupFilters
            groups={filterGroups}
            initiallySelected={defaultSelectedFilter}
            onChange={setSelectedSidebarItem}
          />
          <ResourceFilters
            applicationIds={meta?.application_ids}
            deploymentEnvironments={meta?.deployment_environments}
            setFilters={setModelFilters}
            fieldsToShow={[
              'applications',
              'accounts',
              'license',
              'tags',
              'name',
            ]}
            selected={modelFilters}
            selectedSidebarItem={selectedSidebarItem}
            loading={loading}
          />
        </Grid>
        <Grid item xs={10}>
          <ResourceTable
            loading={loading}
            error={error}
            entity="models"
            data={models}
            columns={modelColumns}
            currentPageSize={currentPageSize}
            hasNextPage={hasNextPage}
            handleNextPage={handleNextPage}
          />
        </Grid>
      </Grid>
    </Content>
  );
};
