import React from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
import { useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { applicationReviewApiRef } from '../api';
import * as S from './styles';
import { Progress, Button } from '@backstage/core-components';
import { DefaultSummary } from './DefaultSummary';
import { DashboardsSummary } from './Dashboards';
import { DataClassificationSummary } from './DataClassification';
import { IReviewSummaryContext, ReviewSummaryContext } from './context';
import { ReviewerCard } from './ReviewerCard';
import { CommentsCard } from './CommentsCard';
import { IssuesCard } from './IssuesCard';
import { DeploymentInformationSummary } from './DeploymentInformation';
import { OpenTracingSummary } from './OpenTracing';
import { APISummary } from './API';
import { TechStackSummary } from './TechStack';
import { AlertsSummary } from './Alerts';
import { PlaybooksSummary } from './Playbooks';
import { LoadTestsSummary } from './LoadTests';
import { UserReference } from 'plugin-ui-components';

declare interface ReviewInfo {
  reviewer?: IAppReview.StoredReviewReviewer;
  time?: string;
  lastRevisedBy?: string;
  lastRevisedAt?: string;
  issues?: IAppReview.StoredReviewIssue[];
  comments?: string;
}

export function ApplicationReviewSummary() {
  const { entity } = useEntity<IEntityApp>();
  const [review, setReview] = React.useState<IAppReview.Review>({});
  const [reviewInfo, setReviewInfo] = React.useState<ReviewInfo>({});
  const api = useApi(applicationReviewApiRef);

  const { loading: loadingReview, value: storedReviews } = useAsync(
    () => api.getReviews(entity.metadata.name, 1),
    [entity, api],
  );

  const { loading: loadingIssue, value: issues } = useAsync(
    () => api.getIssues(entity.metadata.name),
    [entity, api],
  );

  React.useEffect(() => {
    if (
      storedReviews?.length &&
      !Object.keys(review).length &&
      issues?.length
    ) {
      const {
        review: lastReview,
        reviewer,
        time,
        lastRevisedAt,
        lastRevisedBy,
      } = storedReviews[0];
      setReviewInfo({
        reviewer,
        time,
        lastRevisedAt,
        lastRevisedBy,
        issues: issues as IAppReview.StoredReviewIssue[],
        comments: lastReview.comments,
      });

      for (const key in lastReview) {
        if (lastReview.hasOwnProperty(key)) {
          if (key.startsWith('alert_zmon') || !lastReview[key]) {
            delete lastReview[key];
          }
          if (key === 'production_readiness_review_date' && lastReview[key]) {
            const date = new Date(lastReview[key] as string);
            if (!isNaN(date.getTime())) {
              lastReview[key] = date.toISODateString('short');
            }
          }
        }
      }
      setReview(lastReview);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedReviews, issues]);

  const context: IReviewSummaryContext = {
    review,
    entity,
  };

  if (loadingReview || loadingIssue) {
    return <Progress />;
  }

  return (
    <ReviewSummaryContext.Provider value={context}>
      <S.Container>
        <Grid container>
          <Grid item xs={8}>
            <DefaultSummary category="Naming and inclusive language" />
            <DefaultSummary category="Criticality assessment" />
            <DataClassificationSummary />
            <DeploymentInformationSummary />
            <OpenTracingSummary />
            <APISummary />
            <TechStackSummary />
            <DashboardsSummary />
            <DefaultSummary category="Service Level Objectives (SLOs)" />
            <AlertsSummary />
            <PlaybooksSummary />
            <LoadTestsSummary />
            <DefaultSummary category="Production readiness review (PRR)" />
            <DefaultSummary category="Cyber Week" />
          </Grid>
          <Grid item xs={4}>
            <S.StyledCard>
              <S.StyledCardContent>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <S.SuccessAvatar>
                        <CheckIcon />
                      </S.SuccessAvatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography
                          component="span"
                          variant="h4"
                          color="textPrimary"
                        >
                          Reviewed
                        </Typography>
                      }
                      secondary={`Review time: ${new Date(
                        reviewInfo.time as string,
                      ).toISODateString()}`}
                    />
                  </ListItem>
                  {reviewInfo.lastRevisedBy && (
                    <ListItem disableGutters>
                      <ListItemText
                        primary={
                          <Typography
                            component="span"
                            variant="body1"
                            color="textPrimary"
                          >
                            Revised by{' '}
                            <UserReference user={reviewInfo.lastRevisedBy} />
                          </Typography>
                        }
                        secondary={`Revision time: ${new Date(
                          reviewInfo.lastRevisedAt as string,
                        ).toISODateString()}`}
                      />
                    </ListItem>
                  )}
                </List>
              </S.StyledCardContent>
              <Button
                to="?review_again=yes"
                variant="contained"
                color="primary"
              >
                Review again
              </Button>
            </S.StyledCard>
            <ReviewerCard reviewer={reviewInfo.reviewer} />
            <CommentsCard comments={reviewInfo.comments} />
            <IssuesCard issues={reviewInfo.issues} />
          </Grid>
        </Grid>
      </S.Container>
    </ReviewSummaryContext.Provider>
  );
}
