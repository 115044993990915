import { AwareDatetime, Tag } from './common';
import { User } from './user';
import { CatalogMeta } from './meta';

export enum ModelLifecycleStage {
  EXPERIMENTAL = 'EXPERIMENTAL',
  STAGING = 'STAGING',
  PRODUCTION = 'PRODUCTION',
  ARCHIVED = 'ARCHIVED',
}

export enum Origin {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}

export enum LicenseType {
  MIT = 'MIT',
  APACHE = 'APACHE',
  GPL = 'GPL',
  BSD = 'BSD',
  PROPRIETARY = 'PROPRIETARY',
  OTHER = 'OTHER',
}

export type Model = {
  model_id: number;
  name: string;
  application_id: string;
  description?: string;
  meta: Record<string, string>;
  tags: Array<Tag>;
  license_type?: LicenseType;
  license_link?: string;
  created_at: AwareDatetime;
  created_by: User;
  updated_at?: AwareDatetime;
  updated_by?: User;
};

export interface ListModel {
  license_type?: string;
  name?: string;
  application_id?: string;
  account?: string;
  tag_name?: string;
  limit?: number;
  offset?: number;
}

export interface ListModelsResponse {
  meta: CatalogMeta;
  models: Model[];
}

export interface UpdateModelRequest {
  name?: string;
  application_id?: string;
  description?: string;
  meta?: Record<string, string>;
  license_link?: string;
  license_type?: string;
}
