import {
  Entity,
  parseEntityRef,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { CatalogApi, catalogApiRef } from '@backstage/plugin-catalog-react';
import { useUserTeamsApps } from 'plugin-ui-components';
import React from 'react';
import { useQuery } from 'react-query';
import cloneDeep from 'lodash/cloneDeep';

interface ScorecardMetrics {
  passing: number;
  failing: number;
}
interface TeamScorecardMetrics extends ScorecardMetrics {
  score: number;
  notApplicable: number;
}

export interface UserTeamsScorecardsInfo {
  teamMetrics: Map<string, TeamScorecardMetrics>;
  appsByTeam: Map<string, IEntityApp[]>;
  scorecardAssessmentsByTeams: Map<string, ScorecardEntityWithAssessment[][]>;
}

const getScorecardsWithAssessments = async (
  application: Entity,
  catalogApi: CatalogApi,
  scorecardsToShow?: IEntityScorecard[],
  allRelations?: (Entity | undefined)[],
): Promise<{
  assessments: ScorecardEntityWithAssessment[];
  scorecards: IEntityScorecard[];
}> => {
  try {
    // Extract relations from the application, if any
    const appRelations = application.relations || [];
    let scorecards: IEntityScorecard[] = [];
    let assessments: IEntityScorecardAssessment[] = [];
    // If scorecards and assessments are not pre-provided, fetch them
    if (!allRelations) {
      const scorecardRefs = appRelations
        .filter(rel => rel.type === 'consumesScorecard')
        .map(rel => rel.targetRef)
        // If scorecardsToShow is provided, filter to only include those scorecards
        .filter(
          targetRef =>
            !scorecardsToShow ||
            scorecardsToShow.some(
              scorecard =>
                scorecard.metadata.name === parseEntityRef(targetRef).name,
            ),
        );

      const assessmentRefs = appRelations
        .filter(rel => rel.type === 'produces')
        .map(rel => rel.targetRef);

      if (scorecardRefs.length && assessmentRefs.length) {
        // Fetch scorecards and assessments by the combined references
        const response = await catalogApi.getEntitiesByRefs({
          entityRefs: [...scorecardRefs, ...assessmentRefs],
        });

        // Filter response to get scorecards
        scorecards = response.items.filter(
          item => item?.kind === 'Scorecard',
        ) as IEntityScorecard[];

        // Filter response to get assessments
        assessments = response.items.filter(item => {
          const assessment = item as IEntityScorecardAssessment;
          const isRelevantAssessment =
            assessment?.kind === 'ScorecardAssessment' &&
            // If scorecardsToShow is provided, ensure the assessment matches the scorecards
            (!scorecardsToShow ||
              scorecardsToShow.some(
                scorecard =>
                  scorecard.metadata.name ===
                  parseEntityRef(assessment.spec.assessment.scorecard).name,
              ));
          return isRelevantAssessment;
        }) as IEntityScorecardAssessment[];
      }
    } else {
      // If scorecards and assessments are pre-provided, separate them accordingly
      scorecards = allRelations.filter(
        item => item?.kind === 'Scorecard',
      ) as IEntityScorecard[];
      assessments = allRelations.filter(
        item =>
          item?.kind === 'ScorecardAssessment' &&
          // Ensure the assessment is related to the current application
          parseEntityRef(
            (item as IEntityScorecardAssessment).spec.assessment.entityRef,
          ).name === application.metadata.name,
      ) as IEntityScorecardAssessment[];
    }
    // Merge scorecards and assessments into one result array
    const appScorecardsWithAssessments = scorecards.reduce<
      ScorecardEntityWithAssessment[]
    >((result, scorecard) => {
      // Find the corresponding assessment for the current scorecard
      const assessment = assessments.find(
        item =>
          item?.spec.assessment.scorecard === stringifyEntityRef(scorecard),
      );
      if (assessment) {
        // If an assessment is found, combine its spec with the scorecard's spec
        result.push(
          cloneDeep({
            ...scorecard,
            spec: {
              ...scorecard.spec,
              ...assessment.spec.assessment,
            },
          }),
        );
      }
      return result;
    }, []);
    return { assessments: appScorecardsWithAssessments, scorecards };
  } catch (e) {
    throw new Error(
      `Failed to fetch scorecards for this application: ${
        (e as Error).message
      }`,
    );
  }
};

// Hook to fetch all scorecards and assessments related to an application
export const useApplicationScorecards = (
  application: Entity,
  scorecardsToShow?: IEntityScorecard[],
) => {
  const catalogApi = useApi(catalogApiRef);
  return useQuery(`scorecards-${application.metadata.name}`, () =>
    getScorecardsWithAssessments(application, catalogApi, scorecardsToShow),
  );
};

// Hook to fetch all scorecards and assessments related to the user's (watched, owned...) applications
export const useUserApplicationsScorecards = () => {
  const catalogApi = useApi(catalogApiRef);
  const { value: apps = [] } = useUserTeamsApps();

  // Used to avoid fetching relations for each app resulting in multiple HTTP requests
  const refs = React.useMemo(() => {
    return apps.reduce(
      (acc, value) => {
        // Get each relationship type and add it to the corresponding set
        (value.relations || [])
          .filter(rel => rel.type === 'produces')
          .map(rel => rel.targetRef)
          .forEach(rel => acc.assessmentRefs.add(rel));
        (value.relations || [])
          .filter(rel => rel.type === 'consumesScorecard')
          .map(rel => rel.targetRef)
          .forEach(rel => acc.scorecardRefs.add(rel));
        return acc;
      },
      { assessmentRefs: new Set(), scorecardRefs: new Set() } as {
        assessmentRefs: Set<string>;
        scorecardRefs: Set<string>;
      },
    );
  }, [apps]);

  return useQuery(
    `user-applications-scorecards`,
    async () => {
      // Fetch all relations in one go
      const { assessmentRefs, scorecardRefs } = refs;
      const entityRefs = [
        ...assessmentRefs.values(),
        ...scorecardRefs.values(),
      ];
      let allRelations: Array<Entity | undefined> = [];
      if (entityRefs.length) {
        allRelations = (
          await catalogApi.getEntitiesByRefs({
            entityRefs,
          })
        ).items;
      }
      const scorecardsAssessments = await Promise.all(
        apps.map(app =>
          getScorecardsWithAssessments(
            app,
            catalogApi,
            undefined,
            allRelations,
          ).then(r => r.assessments),
        ),
      );

      return {
        scorecardsAssessments,
        scorecards: allRelations.filter(
          item => item?.kind === 'Scorecard',
        ) as IEntityScorecard[],
      };
    },
    {
      enabled: !!apps.length && !!refs,
    },
  );
};

export const useUserTeamsScorecards = () => {
  const { value: apps } = useUserTeamsApps();
  const { data: userAppScorecards } = useUserApplicationsScorecards();
  return useQuery<UserTeamsScorecardsInfo>(
    [`user-teams-scorecards`, apps, userAppScorecards],
    () => {
      return new Promise((resolve, reject) => {
        try {
          // Group applications by team
          const appsByTeam = (() => {
            return apps!.reduce((acc, value) => {
              const owner = value.relations?.find(
                rel => rel.type === 'ownedBy',
              );
              if (owner) {
                const ownerName = parseEntityRef(owner.targetRef).name;
                if (acc.has(ownerName)) {
                  acc.get(ownerName)!.push(value);
                } else {
                  acc.set(ownerName, [value]);
                }
              }
              return acc;
            }, new Map<string, IEntityApp[]>());
          })();
          // Group scorecards assessments by team
          const scorecardAssessmentsByTeams = (() => {
            const map = new Map<string, ScorecardEntityWithAssessment[][]>();
            if (userAppScorecards && appsByTeam) {
              return userAppScorecards.scorecardsAssessments.reduce(
                (acc, value) => {
                  if (value[0]) {
                    const assessmentApp = parseEntityRef(
                      value[0].spec.entityRef,
                    ).name;
                    let appTeam;
                    // Find the team that owns the application related to the assessment
                    Array.from(appsByTeam.keys()).every(team => {
                      if (
                        appsByTeam.get(team)?.some(app => {
                          return app.metadata.name === assessmentApp;
                        })
                      ) {
                        appTeam = team;
                        return false;
                      }
                      return true;
                    });
                    if (appTeam) {
                      // Group each scorecard assessment by team name
                      if (acc.has(appTeam)) {
                        acc.get(appTeam)!.push(value);
                      } else {
                        acc.set(appTeam, [value]);
                      }
                    }
                  }
                  return acc;
                },
                map,
              );
            }
            return map;
          })();
          // Calculate metrics for each team (passing, failing, score)
          const teamMetrics = (() => {
            const metricsByTeam = new Map<string, TeamScorecardMetrics>();
            if (scorecardAssessmentsByTeams) {
              scorecardAssessmentsByTeams.forEach((teamScorecards, team) => {
                const { passing, failing } =
                  teamScorecards.reduce<ScorecardMetrics>(
                    (acc, value) => {
                      let passingCounter = 0;
                      let failingCounter = 0;
                      value.forEach(v => {
                        if (v.spec.completionPercentage === 100) {
                          passingCounter += 1;
                        } else {
                          failingCounter += 1;
                        }
                      });
                      return {
                        passing: acc.passing + passingCounter,
                        failing: acc.failing + failingCounter,
                      };
                    },
                    { passing: 0, failing: 0 },
                  );
                // Count not applicable applications based on exclusions
                let notApplicable = 0;
                userAppScorecards?.scorecards.forEach(sc => {
                  const appsRefs = (appsByTeam.get(team) || []).map(app =>
                    stringifyEntityRef(app),
                  );
                  appsRefs.forEach(r => {
                    if (sc.spec.exclusions.map(x => x.entity_ref).includes(r))
                      notApplicable++;
                  });
                });
                metricsByTeam.set(team, {
                  passing,
                  failing,
                  notApplicable,
                  score:
                    passing + failing > 0
                      ? Math.round((passing / (passing + failing)) * 100)
                      : 0,
                });
              });
              return metricsByTeam;
            }
            return metricsByTeam;
          })();
          // Return the metrics and the scorecard assessments grouped by team
          resolve({
            teamMetrics,
            scorecardAssessmentsByTeams,
            appsByTeam,
          });
        } catch (err) {
          reject(`An error occured getting user scorecards data ${err}`);
        }
      });
    },
    {
      enabled: !!apps && !!userAppScorecards,
    },
  );
};
