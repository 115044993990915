export interface ModelFilterStore {
  tag_name: string;
  account: string;
  name: string;
  license: string;
  origin: string;
  application: string;
}

export const ModelFilterStoreDefault = {
  tag_name: '',
  account: '',
  name: '',
  license: '',
  application: '',
  origin: '',
};

export interface ModelVersionFilterStore {
  stage: string;
  origin: string;
  status: string;
  name: string;
  tag_name: string;
}

export const ModelVersionFilterStoreDefault = {
  stage: '',
  origin: '',
  status: '',
  name: '',
  tag_name: '',
};
