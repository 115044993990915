import React, { useState } from 'react';
import { Model } from '../../api/interfaces/model';
import { useApi } from '@backstage/core-plugin-api';
import { modelsApiRef } from '../../api/services/models';
import {
  ListModelVersionsParams,
  ModelVersion,
} from '../../api/interfaces/model_version';
import { useDebounce, useLocalStorage } from 'react-use';
import {
  ModelVersionFilterStore,
  ModelVersionFilterStoreDefault,
} from '../../api/interfaces/store';
import { Grid } from '@material-ui/core';
import { ResourceFilters } from '../tables/ResourceFilters';
import { useModelVersionColumns } from '../tables/columns/ModelVersions';
import { ResourceTable } from '../tables/ResourceTable';
import { DEFAULT_PAGE_SIZE } from '../../constants/paging';
import { SEARCH_DEBOUNCE_TIME } from 'plugin-core';

export interface ModelVersionListProps {
  model: Model;
  setVersionCount: any;
}

export const ModelVersionList: React.FC<ModelVersionListProps> = ({
  model,
  setVersionCount,
}: ModelVersionListProps) => {
  const modelsApi = useApi(modelsApiRef);
  const [modelVersions, setModelVersions] = useState<ModelVersion[]>([]);
  const [count, setCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const [currentPageSize, setCurrentPageSize] =
    useState<number>(DEFAULT_PAGE_SIZE);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);

  const [modelVersionStore, setModelVersionStore] =
    useLocalStorage<ModelVersionFilterStore>(
      'model-version-filters',
      ModelVersionFilterStoreDefault,
    );

  useDebounce(
    () => {
      const controller = new AbortController();
      setModelVersions([]);

      async function fetchInitialModels() {
        const data = await fetch();
        setModelVersions(data);
        setCount(data.length);
        setVersionCount(data.length);
      }

      fetchInitialModels();
      return () => controller.abort();
    },
    SEARCH_DEBOUNCE_TIME,
    [modelVersionStore],
  );

  async function fetch(offset: number = 0): Promise<ModelVersion[]> {
    const queryParameters: ListModelVersionsParams = {
      model_id: model.model_id,
      offset: offset,
      limit: DEFAULT_PAGE_SIZE,
      stage: modelVersionStore?.stage,
      origin: modelVersionStore?.origin,
      review_status: modelVersionStore?.status,
      name: modelVersionStore?.name,
    };

    let data: ModelVersion[] = [];
    setLoading(true);
    setError(null);
    setCount(0);

    try {
      const response = await modelsApi.getModelVersions(queryParameters);
      data = response.versions || [];
      setCount(response.total);
    } catch (e) {
      const err = e as Error;
      setError(err);
    }
    setLoading(false);
    return data;
  }

  async function handleNextPage() {
    const newModelVersions = await fetch(currentPageSize);
    setModelVersions([...modelVersions, ...newModelVersions]);
    setCurrentPageSize(currentPageSize + DEFAULT_PAGE_SIZE);
    setHasNextPage(count > currentPageSize);
  }

  const modelVersionColumns = useModelVersionColumns();

  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <div>
          <ResourceFilters
            fieldsToShow={[
              'model_stage',
              'origin',
              'review_status',
              'tags',
              'name',
            ]}
            selected={modelVersionStore}
            setFilters={setModelVersionStore}
            loading={loading}
          />
        </div>
      </Grid>
      <Grid item xs={10}>
        <ResourceTable
          loading={loading}
          entity="model versions"
          error={error}
          columns={modelVersionColumns}
          data={modelVersions}
          currentPageSize={currentPageSize}
          hasNextPage={hasNextPage}
          handleNextPage={handleNextPage}
          padding="dense"
        />
      </Grid>
    </Grid>
  );
};
