import React, { useMemo, useRef } from 'react';
import { Model } from '../../api/interfaces/model';
import {
  CodeSnippet,
  Link,
  Table,
  WarningPanel,
} from '@backstage/core-components';
import { reachOutLink, tableOptions } from './utils';
import { Box, Button, makeStyles, Paper, Typography } from '@material-ui/core';
import { EventTracker } from 'plugin-ui-components';
import { TableColumn } from '@backstage/core-components';
import { DEFAULT_PAGE_SIZE } from '../../constants/paging';
import { ModelVersion } from '../../api/interfaces/model_version';
import { usePluginTheme } from '../../hooks/usePluginTheme';

export type ResourceTableProps = {
  loading: boolean;
  error?: any;
  data?: Model[] | ModelVersion[];
  entity: 'models' | 'model versions';
  columns: TableColumn<any>[];
  currentPageSize: number;
  hasNextPage?: boolean;
  handleNextPage?: () => Promise<void>;
  padding?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  pagination: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const EmptyTable: React.FC<{ error: any; entity: string }> = ({
  error,
  entity,
}) => (
  <Box
    height="350px"
    textAlign="center"
    display="flex"
    alignItems="center"
    padding="0 200px"
  >
    {error ? (
      <WarningPanel
        severity="error"
        title={`Failed to load ${entity}. Please try again by refreshing the page.`}
      >
        <CodeSnippet language="text" text={error.toString()} />
      </WarningPanel>
    ) : (
      <Typography>
        {`We couldn't find any ${entity}. If you believe this is an error, please reach out to the `}
        <EventTracker interaction="onClick" {...reachOutLink(entity)}>
          <Link
            to="https://github.bus.zalan.do/zai/issues/issues/new"
            color="primary"
          >
            ML Orchestration team
          </Link>
        </EventTracker>
      </Typography>
    )}
  </Box>
);

const PaginationControl: React.FC<{
  hasNextPage?: boolean;
  handleNextPage?: () => Promise<void>;
  classes: any;
}> = ({ hasNextPage, handleNextPage, classes }) => (
  <Box
    padding={1}
    display="flex"
    justifyContent="center"
    className={classes.pagination}
  >
    <Button onClick={handleNextPage} disabled={!hasNextPage}>
      Load {DEFAULT_PAGE_SIZE} more
    </Button>
  </Box>
);

export const ResourceTable: React.FC<ResourceTableProps> = ({
  error,
  data = [],
  entity,
  columns,
  currentPageSize,
  hasNextPage,
  handleNextPage,
  padding = 'default',
}) => {
  const classes = useStyles();
  const tableRef = useRef<{
    dataManager?: { changePageSize: (size: number) => void };
  }>(null);

  const pluginTheme = usePluginTheme();

  useMemo(() => {
    tableRef?.current?.dataManager?.changePageSize(currentPageSize);
  }, [currentPageSize]);

  if (error || data.length === 0) {
    return (
      <Paper variant="elevation" className="full-width">
        <EmptyTable error={error} entity={entity} />
      </Paper>
    );
  }

  return (
    <Paper variant="elevation" className="full-width">
      <Table<Model>
        tableRef={tableRef}
        options={{
          ...(tableOptions as any),
          pageSize: currentPageSize,
          padding: padding,
          rowStyle: () => ({
            backgroundColor: pluginTheme === 'dark' ? '#1E1E1E' : '#fff',
            borderBottom: '1px solid #E0E0E0',
            padding: '100%',
          }),
        }}
        columns={columns}
        data={data as any}
        components={{
          Toolbar: () => <div style={{ height: '0px' }} />,
          Pagination: () => (
            <PaginationControl
              hasNextPage={hasNextPage}
              handleNextPage={handleNextPage}
              classes={classes}
            />
          ),
        }}
      />
    </Paper>
  );
};
