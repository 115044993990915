import React from 'react';
import { Tooltip } from '@material-ui/core';
import {
  durationHumanized,
  getFormattedDateTime,
  timeAgo,
} from 'plugin-ui-components';
import * as S from '../../../styles/table';
import { StyledGridColumn } from '../../../styles/grid';
import { Model } from '../../../api/interfaces/model';
import { TableColumn } from '@backstage/core-components';

const getCreatedAt = (resource: Model): string | undefined => {
  if ('last_run' in resource) {
    const lastRun = resource.last_run as any;
    return lastRun?.created_at;
  }
  return resource.created_at;
};

const getUpdatedAt = (resource: Model): string | undefined => {
  return resource.updated_at;
};

const getResourceType = (resource: Model): string | null => {
  if ('model_id' in resource) {
    return 'model';
  }
  if ('run_id' in resource) {
    return 'run';
  }

  return null;
};

export const createTimeColumn = (): TableColumn<Model> => {
  return {
    title: 'Time',
    field: 'created_at',
    filtering: false,
    sorting: false,
    render: (resource: Model) => {
      const createdAt = getCreatedAt(resource);
      const updatedAt = getUpdatedAt(resource);
      const isRun = getResourceType(resource) === 'run';

      return (
        <StyledGridColumn item>
          {createdAt && (
            <Tooltip
              title={isRun ? getFormattedDateTime(createdAt) : 'Created At'}
            >
              <S.Text>
                {isRun ? timeAgo(createdAt) : getFormattedDateTime(createdAt)}
              </S.Text>
            </Tooltip>
          )}

          {isRun && createdAt && updatedAt && (
            <Tooltip title="Duration">
              <S.TextLight>
                {durationHumanized(createdAt, updatedAt)}
              </S.TextLight>
            </Tooltip>
          )}
        </StyledGridColumn>
      );
    },
  };
};

export default createTimeColumn;
