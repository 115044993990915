import React from 'react';
import { List, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { OnChangeCallback } from 'plugin-ui-components';
import { useSearchParams } from 'react-router-dom';
import * as S from '../../styles/groupFilters';
import { MLResourceButtonGroup } from './FilterGroups';

interface GroupFilterProps {
  groups: MLResourceButtonGroup[];
  initiallySelected: string;
  onChange?: OnChangeCallback;
}

export const GroupFilters = ({
  groups,
  initiallySelected,
  onChange,
}: GroupFilterProps) => {
  const [_, setSearchParams] = useSearchParams();

  const [currentFilter, setCurrentFilter] =
    React.useState<string>(initiallySelected);
  const onChangeRef = React.useRef<OnChangeCallback>();
  React.useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  const setCurrent = React.useCallback(
    (item: { id: string; label: string }) => {
      setCurrentFilter(item.id);
      setSearchParams(currentVal => {
        currentVal.set('group', item.id);
        return currentVal;
      });
      onChangeRef.current?.({ id: item.id, label: item.label });
    },
    [setCurrentFilter, setSearchParams],
  );

  React.useEffect(() => {
    const items = groups.flatMap(g => g.items);
    const item = items.find(i => i.id === initiallySelected) || items[0];
    if (item) {
      onChangeRef.current?.({ id: item.id, label: item.label });
    }
    // intentionally only happens on startup
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.Wrapper id="category-filter">
      {groups.map(group => (
        <React.Fragment key={group.name}>
          <S.Title variant="subtitle2">{group.name}</S.Title>
          <S.GroupWrapper>
            <List disablePadding dense>
              {group.items.map(item => (
                <S.MenuItem
                  key={item.id}
                  button
                  divider
                  onClick={() => setCurrent(item)}
                  selected={item.id === currentFilter}
                  disableRipple
                >
                  {item.icon && (
                    <S.ListItemIcon>
                      <item.icon fontSize="small" />
                    </S.ListItemIcon>
                  )}
                  <ListItemText>
                    <S.MenuTitle>{item.label}</S.MenuTitle>
                  </ListItemText>
                  <ListItemSecondaryAction>
                    {item.count}
                  </ListItemSecondaryAction>
                </S.MenuItem>
              ))}
            </List>
          </S.GroupWrapper>
        </React.Fragment>
      ))}
    </S.Wrapper>
  );
};
