import LinearProgress from '@mui/material/LinearProgress';
import Grid from '@mui/material/Grid';
import {
  ApplicationResultsFilter,
  CatalogTable,
  FilterGroupsContext,
  useProvideEntityFilters,
} from 'plugin-ui-components';
import React from 'react';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useApi } from '@backstage/core-plugin-api';
import Alert from '@mui/material/Alert';
import { makeColumns } from './ScorecardTargetsColumns';
import { useAsync } from 'react-use';
import { stringifyEntityRef } from '@backstage/catalog-model';

interface AsyncEntities {
  apps: IEntityComponent[];
  assessments: IEntityScorecardAssessment[];
}
const defaultValue: AsyncEntities = { apps: [], assessments: [] };

export const ScorecardTargets = ({
  scorecard,
}: {
  scorecard: IEntityScorecard;
}) => {
  const catalogApi = useApi(catalogApiRef);

  const {
    value: { apps, assessments } = defaultValue,
    loading,
    error,
  } = useAsync(async (): Promise<AsyncEntities> => {
    if (!scorecard.relations) return { apps: [], assessments: [] };
    const entityRefs = scorecard.relations
      .filter(r => r.type === 'scorecardConsumedBy')
      .reduce((output, r) => {
        output.push(r.targetRef);
        output.push(
          stringifyEntityRef({
            name: `${scorecard.metadata.name}-${r.target.name}`,
            kind: 'ScorecardAssessment',
          }),
        );
        return output;
      }, [] as string[]);
    const entities = await catalogApi
      .getEntitiesByRefs({
        entityRefs,
      })
      .then(r => r.items.filter(Boolean) as IEntity[]);
    return {
      apps: entities.filter(
        e => e.kind.toLowerCase() === 'component',
      ) as IEntityComponent[],
      assessments: entities.filter(
        e => e.kind.toLowerCase() === 'scorecardassessment',
      ) as any as IEntityScorecardAssessment[],
    };
  }, [catalogApi]);

  const state = useProvideEntityFilters('service', apps, loading);
  return (
    <FilterGroupsContext.Provider value={state}>
      <Grid container>
        <Grid item xs={3}>
          <ApplicationResultsFilter />
        </Grid>
        {/* Target applications */}
        <Grid item xs={9} marginTop="1.5rem">
          {apps && (
            <CatalogTable
              entities={state.matchingEntities}
              loading={loading}
              error={error}
              titlePreamble="Applies to"
              columns={makeColumns({ scorecard, assessments })}
            />
          )}
          {loading && <LinearProgress />}
          {error && (
            <Alert severity="error">
              An error occured while loading target entities:{' '}
              {(error as Error).message}
            </Alert>
          )}
        </Grid>
      </Grid>
    </FilterGroupsContext.Provider>
  );
};
