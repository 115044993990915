import {
  Chip,
  styled,
  Typography,
  Container as MuiContainer,
  FormLabel,
  StepButton,
  Card,
  CardHeader,
  CardContent,
  Avatar,
  Theme,
  makeStyles,
} from '@material-ui/core';

export const Container = styled(MuiContainer)(({ theme }) => ({
  padding: 0,
  '& dl': {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    columnGap: 40,
    rowGap: 20,
  },
  '& dd': {
    margin: 0,
    '& svg.tooltip': {
      fontSize: '1em',
      verticalAlign: 'middle',
      marginLeft: theme.spacing(0.25),
      cursor: 'pointer',
    },

    '& [class*=MuiChip]': {
      margin: 0,
    },

    '& a': {
      color: (theme.palette as any).link,
    },
  },
  '& [role=alert]': {
    marginTop: theme.spacing(1),
    borderRadius: 6,

    '& p': {
      margin: 0,
    },
  },
  '& code': {
    backgroundColor: 'var(--code-bg)',
    color: theme.palette.text.hint,
    borderRadius: 4,
    padding: '0.3em 0.5em',
    fontSize: '0.75em',
  },
  '& [data-success]': {
    background: theme.palette.success.main,
    color: theme.palette.common.white,
  },
  '& p:not([class*=MuiFormHelperText), label': {
    fontSize: theme.typography.body2.fontSize,
  },
  '& h1': {
    fontSize: theme.spacing(5),
  },
  '& h2': {
    fontSize: theme.spacing(3),
    margin: 0,
  },
  '& h3': {
    fontSize: theme.spacing(2),
    margin: theme.spacing(2, 0),
  },
  '& .p-inline-0': {
    paddingInline: 0,
  },
  '& span.badge': {
    padding: '0.1rem 0.3rem',
    borderRadius: 20,
    whiteSpace: 'nowrap',
    '&.error': {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
    },
    '&.success': {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.common.white,
    },
    '&.warning': {
      backgroundColor: theme.palette.warning.main,
    },
  },
  '& .bg-none': {
    background: 'none',
  },
  '& a > svg': {
    fontSize: '0.8em',
  },
  '& .top-arrow': {
    position: 'relative',

    '& ::before': {
      content: '" "',
      width: 30,
      height: 30,
      margin: 'auto',
      display: 'block',
      background: '#ffecea',
      position: 'absolute',
      left: '50%',
      top: -14,
      clipPath: 'polygon(0 50%, 50% 0, 100% 50%)',
    },
  },
  '& .mb-2': {
    marginBottom: theme.spacing(2),
  },
  '& .review-section-icon': {
    verticalAlign: 'middle',
    marginLeft: theme.spacing(1),
    width: '0.8em',
    height: '0.8em',

    '&.error': {
      color: theme.palette.error.main,
    },
    '&.warn': {
      color: theme.palette.warning.main,
    },
  },
}));

export const Pill = styled(Chip)(({ theme }) => ({
  marginBlock: 0,

  '&[data-color]': {
    color: 'white',
  },
  '&[data-color=error]': {
    backgroundColor: theme.palette.error.main,
  },
  '&[data-color=warning]': {
    backgroundColor: theme.palette.warning.light,
  },
  '&[data-color=success]': {
    backgroundColor: theme.palette.success.main,
  },
  '&[data-color=secondary]': {
    backgroundColor: theme.palette.common.black,
  },
  '&[data-color=default]': {
    backgroundColor: theme.palette.background.default,
    color: 'currentColor',
  },
}));

export const FormActions = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: theme.spacing(2),
}));

export const Caption = styled(Typography)(({ theme }) => ({
  display: 'block',
  marginTop: theme.spacing(2),

  '& svg': {
    verticalAlign: 'middle',
    fontSize: '1.25em',
    marginRight: '0.3em',
  },
}));

export const RadioSelectLabel = styled(FormLabel)(({ theme }) => ({
  display: 'block',
  lineHeight: 'inherit',
  marginBottom: theme.spacing(2),
}));

export const CustomStepButton = styled(StepButton)(({ theme }) => ({
  /** Increases the size of the stepper section headers  */
  '& [class*=MuiStepLabel-label]': {
    fontSize: theme.spacing(3),
  },
}));

export const Label = styled(Typography)(({ theme }: { theme: Theme }) => ({
  marginBottom: theme.spacing(2),
  '& svg': {
    fontSize: theme.typography.body1.fontSize,
    verticalAlign: 'middle',
  },
}));

export const StyledCard = styled(Card)(({ theme }: { theme: Theme }) => ({
  marginBottom: theme.spacing(4),
  '&:last-child': {
    marginBottom: 0,
  },
}));

export const StyledCardHeader = styled(CardHeader)(
  (props: { theme: Theme }) => ({
    padding: props.theme.spacing(2),
    '& > *': {
      margin: 0,
    },
    '& h3': {
      fontSize: '1.3rem',
    },
  }),
);

export const StyledCardContent = styled(CardContent)(() => ({
  paddingTop: 0,
  paddingBottom: 0,
}));

export const SuccessAvatar = styled(Avatar)(({ theme }: { theme: Theme }) => ({
  backgroundColor: theme.palette.success.main,
  color: '#fff',
  width: 35,
  height: 35,
}));

export const useStyles = makeStyles(() => ({
  links: {
    paddingLeft: 5,
  },
}));
