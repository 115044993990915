export default `
query Models(
  $account: String, 
  $application_id: String, 
  $limit: Int, 
  $offset: Int, 
  $name: String, 
  $license_type: String,
  $tag_name: String, 
  $include: [String!]
) {
  meta {
    application_ids
    deployment_environments {
      deployment_environment_id
      cluster_registry_id
      type
      production_status
      name
    }
    totals
  }
  models(
    application_id: $application_id
    account: $account
    name: $name
    tag_name: $tag_name
    limit: $limit
    offset: $offset
    license_type: $license_type
    include: $include
  ) {
    name
    model_id
    description
    meta
    updated_at
    license_link
    license_type
    application_id
    created_at
    created_by {
      realm
      uid
    }
    tags {
      name
      value
    }
    updated_by {
      realm
      uid
    }
  }
}
`;
