import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Home } from './components/pages/Home';
import { ModelDetails } from './components/pages/ModelDetails';

export const MLRouter = () => {
  return (
    <Routes>
      <Route path="/*" element={<Home />} />
      <Route path="/models/:modelId" element={<ModelDetails />} />
    </Routes>
  );
};
