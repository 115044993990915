import { Model } from '../../../../api/interfaces/model';
import { createDescriptionColumn, createNameColumn } from './common';
import { createTimeColumn } from '../common';
import { useMemo } from 'react';
import { TableColumn } from '@backstage/core-components';

export const useModelColumns = (): TableColumn<Model>[] => {
  return useMemo(() => {
    return [createNameColumn(), createTimeColumn(), createDescriptionColumn()];
  }, []);
};
