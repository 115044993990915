import React, { useEffect, useState } from 'react';
import { MLUILayout } from '../../common/Layout';
import { useParams } from 'react-router';
import { modelsApiRef } from '../../../api/services/models';
import { Model } from '../../../api/interfaces/model';
import { ResourceNotFound } from '../../../api/interfaces/common';
import { useApi } from '@backstage/core-plugin-api';
import { Box, Grid } from '@material-ui/core';
import { BasicDetails } from './BasicDetails';
import { Content } from '@backstage/core-components';
import { ModelVersionList } from '../../model_versions/List';

export const ModelDetails = () => {
  const { modelId = '' } = useParams();
  const modelsApi = useApi(modelsApiRef);
  const [resourceNotFound, setResourceNotFound] = useState<ResourceNotFound>();
  const [model, setModel] = useState<Model>();
  const [loading, setLoading] = useState<boolean>(true);
  const [versionCount, setVersionCount] = useState<number>(0);

  useEffect(() => {
    modelsApi
      .getModelById(modelId)
      .then(modelResponse => {
        setModel(modelResponse);
        setLoading(false);
      })
      .catch(_ => setResourceNotFound({ name: 'model', id: modelId }));
  }, [modelId, modelsApi]);

  return (
    <MLUILayout
      namedEntities={{
        modelName: model?.name,
      }}
    >
      <Content>
        {model && !resourceNotFound && (
          <Box sx={{ width: '100%' }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <BasicDetails
                  model={model}
                  versionCount={versionCount}
                  loading={loading}
                />
              </Grid>
              <Grid item xs={12}>
                <ModelVersionList
                  model={model}
                  setVersionCount={setVersionCount}
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </Content>
    </MLUILayout>
  );
};
