import React, { useMemo } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { PathMatch } from 'react-router';
import { matchPath } from 'react-router-dom';
import { Link, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Stack from '@mui/material/Stack';

export interface NamedEntities {
  modelName?: string;
  modelVersionName?: string;
  pipelineName?: string;
  runName?: string;
}

const paths = ['/mlp/models/:modelId'];

const createBreadcrumbItems = (
  pm: PathMatch,
  namedEntities?: NamedEntities,
) => {
  const { modelId } = pm.params;

  const items: Array<React.ReactNode> = [];

  const addItem = (path: string, text: string) => {
    items.push(
      <Link underline="hover" key={path} color="inherit" href={path}>
        {text}
      </Link>,
    );
  };

  if (pm.pathname.includes('/models')) {
    addItem('/mlp/models', 'Models');
    if (modelId) {
      const modelName = namedEntities?.modelName as string;
      addItem(`/mlp/models/${modelId}`, modelName);
    }
  }

  return items;
};

export const MLUIBreadcrumbs = ({
  pathname,
  namedEntities,
}: {
  pathname: string;
  namedEntities?: NamedEntities;
}) => {
  const pathMatch = useMemo(() => {
    return paths.reduce((pathObject: PathMatch | null, current: string) => {
      const match = matchPath(current, pathname);
      return match || pathObject;
    }, null);
  }, [pathname]);

  if (!pathMatch) {
    return (
      <Typography style={{ color: 'white' }}>
        Discover the latest ML pipelines, models, experiments and datasets
        across Zalando.
      </Typography>
    );
  }

  const breadcrumbs = createBreadcrumbItems(pathMatch, namedEntities);

  return (
    <>
      <Stack spacing={2}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
    </>
  );
};
