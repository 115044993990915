import { colors } from 'plugin-ui-components';
import { ModelVersionReviewStatus } from '../../api/interfaces/model_version_review';

export const tableOptions = {
  filtering: false,
  emptyRowsWhenPaging: false,
  paging: true,
};

export const reachOutLink = (resourceType: string): IEventTracker => ({
  plugin: 'ml-platform',
  eventCategory: `empty-${resourceType}`,
  eventLabel: `Reach out to team link is clicked from empty owned ${resourceType}`,
  eventAction: 'click',
});

export const getApprovalColor = (
  status: ModelVersionReviewStatus | undefined,
) => {
  switch (status) {
    case 'APPROVED':
      return colors.semantic.successBase;
    case 'REJECTED':
      return colors.semantic.errorBase;
    case 'PENDING':
      return colors.semantic.infoBase;
    default:
      return colors.semantic.infoBase;
  }
};
