import React from 'react';
import { Button } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Snackbar, useMediaQuery, useTheme } from '@material-ui/core';
import { EditModelForm } from '../../forms/EditModelForm';
import { modelsApiRef } from '../../../api/services/models';
import { useApi } from '@backstage/core-plugin-api';
import { Alert } from '@material-ui/lab';

export interface EditDialogProps {
  open: boolean;
  name: string;
  description?: string;
  license?: string;
  licenseLink?: string;
  model_id: number;
  handleClose: () => void;
  setUpdatedModel: (value: ((prevState: boolean) => boolean) | boolean) => void;
}

export const EditDialog = ({
  open,
  name,
  handleClose,
  description,
  license,
  licenseLink,
  model_id,
  setUpdatedModel,
}: EditDialogProps) => {
  const modelsApi = useApi(modelsApiRef);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [updatingModel, setUpdatingModel] = React.useState<boolean>(false);
  const [updateError, setUpdateError] = React.useState<string>();
  const [openAlert, setOpenAlert] = React.useState(false);
  const [changeMade, setChangeMade] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);

  const [form, setForm] = React.useState({
    name: name,
    description: description,
    license_type: license,
    license_link: licenseLink,
  });

  const handleSubmit = () => {
    setUpdatingModel(true);
    modelsApi
      .updateModel(model_id, form)
      .then(() => {
        handleClose();
        setUpdatedModel(true);
      })
      .catch(error => {
        setUpdateError(`${error}`);
      });
    setUpdatingModel(false);
    setOpenAlert(true);
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="responsive-dialog-title">Edit Model</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Edit information about the model. You can change the name,
            description, and license.
          </DialogContentText>
          <br />
          {updateError && updateError.length > 0 && (
            <DialogContentText>
              <Alert color="error">{updateError}</Alert>
            </DialogContentText>
          )}
          <br />
          <EditModelForm
            setForm={setForm}
            form={form}
            updatingModel={updatingModel}
            setChangeMade={setChangeMade}
            setHasError={setHasError}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            size="medium"
            color="primary"
            disabled={updatingModel || !changeMade || hasError}
            onClick={handleSubmit}
          >
            Update
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="medium"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openAlert}
        autoHideDuration={3000}
        onClose={() => setOpenAlert(false)}
      >
        <Alert
          onClose={() => setOpenAlert(false)}
          severity={updateError ? 'error' : 'success'}
          variant="filled"
        >
          {updateError ? 'Error updating model' : 'Model updated successfully'}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};
