import {
  createApiRef,
  DiscoveryApi,
  OAuthApi,
} from '@backstage/core-plugin-api';
import {
  ListModel,
  ListModelsResponse,
  Model,
  UpdateModelRequest,
} from '../interfaces/model';
import { HttpClient } from '../httpClient';
import modelsQuery from '../graphql/models';
import { InputError } from '@backstage/errors';
import {
  ListModelVersionsParams,
  ListModelVersionsResponse,
  ModelVersion,
} from '../interfaces/model_version';

interface GetModelByNameBody {
  modelName: string;
}

export interface ModelServiceApi {
  getModelByName(modelName: GetModelByNameBody): Promise<Model>;
  getModelById(modelId: string): Promise<Model>;
  getModels(getModelsParameters: ListModel): Promise<ListModelsResponse>;
  updateModel(modelId: number, model: UpdateModelRequest): Promise<number>;
  getModelVersions(
    listModelVersionParams: ListModelVersionsParams,
  ): Promise<ListModelVersionsResponse>;
}

export class ModelService implements ModelServiceApi {
  readonly httpClient: HttpClient;

  constructor(options: { oauth2Api: OAuthApi; discoveryApi: DiscoveryApi }) {
    this.httpClient = new HttpClient({
      pluginProxyEndpoint: 'ml',
      ...options,
    });
  }

  async getModelByName(modelName: GetModelByNameBody): Promise<Model> {
    return this.httpClient
      .get(`/models/`, {
        name: modelName.modelName,
        limit: 1,
      })
      .then(response => {
        return response.items[0] as Model;
      });
  }

  async getModelById(modelId: string): Promise<Model> {
    return this.httpClient.get(`/models/${modelId}`);
  }

  async getModels(getModelsParameters: ListModel): Promise<ListModelsResponse> {
    const variables = this.httpClient.cleanUpParameters(getModelsParameters);
    return this.httpClient
      .post('/graphql', {
        operationName: 'Models',
        query: modelsQuery,
        variables,
      })
      .then(response => {
        if (response.data.errors && !response.data.data) {
          throw new InputError(
            response.errors[0]?.message ||
              'There was a problem with fetching the models data',
          );
        }
        return response.data;
      });
  }

  async updateModel(
    modelId: number,
    model: UpdateModelRequest,
  ): Promise<number> {
    const parameters = this.httpClient.cleanUpParameters(model);
    return this.httpClient.patch(`/models/${modelId}`, parameters);
  }

  async getModelVersions(
    listModelVersionParams: ListModelVersionsParams,
  ): Promise<ListModelVersionsResponse> {
    const { model_id, ...queryParams } = listModelVersionParams;
    const filteredParams = this.httpClient.cleanUpParameters(queryParams);

    return this.httpClient
      .get(`/model_versions/`, {
        model_id,
        ...filteredParams,
      })
      .then(response => {
        return {
          versions: response.items as ModelVersion[],
          total: response.total,
        } as ListModelVersionsResponse;
      });
  }
}

export const modelsApiRef = createApiRef<ModelServiceApi>({
  id: 'ml-platform-models-api',
});
