import React from 'react';
import Chip from '@mui/material/Chip';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import { WarningIcon } from '@backstage/core-components';
import Popover from '@mui/material/Popover';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Box from '@material-ui/core/Box';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { CardFooterLink, TrackedInteraction } from 'plugin-ui-components';
import Button from '@mui/material/Button';
import { useApplicationScorecards } from '../../../hooks/index';
import { viewAllScorecards, viewScorecardDetails } from '../../../tracking';
import { calculateScorecardCompletion, getColor } from '../../../utils';
import { ScorecardCompletion } from './ScorecardCompletion';

interface ScorecardChipProps {
  entity: IEntity;
  scorecardsToShow?: IEntityScorecard[];
}

export const ScorecardChip = ({
  entity,
  scorecardsToShow,
}: ScorecardChipProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const popoverId = 'scorecard-popover';
  const {
    kind = 'Component',
    metadata: { namespace = 'default', name },
  } = entity;

  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  const {
    data: { assessments } = {},
    isError,
    isLoading,
  } = useApplicationScorecards(entity, scorecardsToShow);

  const completion = React.useMemo(() => {
    if (!assessments || !assessments.length) return 0;
    return calculateScorecardCompletion(assessments);
  }, [assessments]);

  return (
    <>
      {isError && (
        <Tooltip title="An error occurred while fetching the data.">
          <Chip
            size="small"
            variant="outlined"
            className="info-chip error"
            label={
              <>
                <WarningIcon />
                Error
              </>
            }
          />
        </Tooltip>
      )}
      {isLoading && <Skeleton width="100%" />}
      {Boolean(!isLoading && assessments && assessments.length) && (
        <TrackedInteraction {...viewScorecardDetails(entity.metadata.name)}>
          <Button
            onClick={handleOpenPopover}
            variant="text"
            aria-describedby={popoverId}
            style={{ textTransform: 'none', color: 'inherit' }}
          >
            <Chip
              size="small"
              variant="outlined"
              label={`${completion}%`}
              className={`info-chip ${getColor(completion)}`}
            />
          </Button>
        </TrackedInteraction>
      )}
      {assessments && !assessments.length && (
        <Tooltip title="This application has no linked scorecards">
          <Chip
            size="small"
            variant="outlined"
            className="info-chip"
            label="N/A"
            style={{ cursor: 'help' }}
          />
        </Tooltip>
      )}
      <Popover
        id={popoverId}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        elevation={4}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <>
          <Box padding={2} paddingBottom={1}>
            {assessments
              ?.filter(sc => !!sc.spec.checks.length)
              .slice(0, 3)
              .map(scorecard => (
                <Box marginBottom={1}>
                  <Typography variant="body2">
                    {scorecard.metadata.title}
                  </Typography>
                  <ScorecardCompletion
                    completion={scorecard.spec.completionPercentage}
                    type="linear"
                  />
                </Box>
              ))}
          </Box>
          <Divider />
          <TrackedInteraction {...viewAllScorecards(entity.metadata.name)}>
            <CardFooterLink
              variant="text"
              to={`/catalog/${namespace}/${kind}/${name}/scorecards`}
            >
              See all Scorecards for <strong>{name}</strong> <ArrowForward />
            </CardFooterLink>
          </TrackedInteraction>
        </>
      </Popover>
    </>
  );
};
