import React from 'react';
import { TabbedLayout } from '@backstage/core-components';
import { MLUILayout } from '../common/Layout';
import { Models } from './Models';
import { useLocalStorage } from 'react-use';
import {
  ModelFilterStore,
  ModelFilterStoreDefault,
} from '../../api/interfaces/store';

export const Home = () => {
  const [modelStore, setModelStore] = useLocalStorage<ModelFilterStore>(
    'model-filters',
    ModelFilterStoreDefault,
  );

  return (
    <MLUILayout>
      <TabbedLayout>
        <TabbedLayout.Route path="#" title="Models">
          <Models modelFilters={modelStore} setModelFilters={setModelStore} />
        </TabbedLayout.Route>
      </TabbedLayout>
    </MLUILayout>
  );
};
