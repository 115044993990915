import React, { useCallback, useMemo } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { ApplicationFilter } from './ResultsFilter/ApplicationFilter';
import { DeploymentEnvironment } from '../../api/interfaces/deployment_environment';
import { AccountFilter } from './ResultsFilter/AccountFilter';
import { TagInputFilter } from './ResultsFilter/FilterTypes';
import { useStyles } from '../../styles/catalog';
import { ResourceNameFilter } from './ResultsFilter/ResourceNameFilter';
import { LicenseType, Origin } from '../../api/interfaces/model';
import { EnumFieldFilter } from './ResultsFilter/EnumFilter';
import { ModelFilterStore } from '../../api/interfaces/store';

interface ResourceFilterProps {
  fieldsToShow: string[];
  deploymentEnvironments?: DeploymentEnvironment[];
  applicationIds?: string[];
  selected: any;
  selectedSidebarItem?: { id: string; label: string } | undefined;
  loading: boolean;
  setFilters: any;
}

export const ResourceFilters: React.FC<ResourceFilterProps> = ({
  selected,
  fieldsToShow,
  deploymentEnvironments = [],
  applicationIds = [],
  selectedSidebarItem,
  loading,
  setFilters,
}) => {
  const { filterWrapper } = useStyles();

  const getApplicationIds = useMemo(() => {
    if (
      selectedSidebarItem?.id !== 'mine' &&
      fieldsToShow.includes('applications')
    ) {
      return applicationIds.map(id => ({
        label: id || 'Unknown',
        value: id || '',
      }));
    }
    return [];
  }, [selectedSidebarItem?.id, fieldsToShow, applicationIds]);

  const getDeploymentEnvironmentIds = useMemo(
    () =>
      fieldsToShow.includes('accounts')
        ? deploymentEnvironments.map(env => ({
            label: env.name || 'Unknown',
            value: env.cluster_registry_id?.replace('aws:', '') || '',
          }))
        : [],
    [fieldsToShow, deploymentEnvironments],
  );

  const updateFilters = useCallback(
    (key: keyof ModelFilterStore, value: string) => {
      setFilters((prevStore: any) => ({
        ...prevStore,
        [key]: value,
      }));
    },
    [setFilters],
  );

  const clearFilters = useCallback(() => {
    setFilters((prevStore: any) => {
      return Object.keys(prevStore).reduce((acc, key) => {
        acc[key as keyof ModelFilterStore] = ''; // Reset each property to an empty string
        return acc;
      }, {} as ModelFilterStore);
    });
  }, [setFilters]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      marginTop="1.5rem"
      gridRowGap="0.5rem"
      padding="0.5rem"
      className={filterWrapper}
    >
      <Box display="flex" justifyContent="space-between" alignItems="baseline">
        <Typography variant="h6" style={{ margin: 0, fontSize: '0.75rem' }}>
          Refine Results
        </Typography>
        <Button
          onClick={clearFilters as any}
          style={{ fontSize: '0.75rem', padding: 0, minWidth: 'auto' }}
          variant="text"
        >
          Clear
        </Button>
      </Box>

      {fieldsToShow.includes('applications') && (
        <ApplicationFilter
          applicationIds={getApplicationIds}
          onChange={value => updateFilters('application', value)}
          selected={selected?.application || ''}
          loading={loading}
        />
      )}

      {fieldsToShow.includes('accounts') && (
        <AccountFilter
          accounts={getDeploymentEnvironmentIds}
          onChange={value => updateFilters('account', value)}
          selected={selected?.account || ''}
          loading={loading}
        />
      )}

      {fieldsToShow.includes('tags') && (
        <TagInputFilter
          onChange={value => updateFilters('tag_name', value)}
          selected={selected?.tag_name || ''}
          id="tag-filter"
          label="Tags"
          disabled={loading}
        />
      )}

      {fieldsToShow.includes('name') && (
        <ResourceNameFilter
          onChange={value => updateFilters('name', value)}
          selected={selected?.name || ''}
          loading={loading}
        />
      )}

      {fieldsToShow.includes('origin') && (
        <EnumFieldFilter
          onChange={value => updateFilters('origin', value)}
          selected={selected?.origin || ''}
          enumField={Origin}
          label="Origin"
          id="model-version-origin"
          loading={loading}
        />
      )}

      {fieldsToShow.includes('license') && (
        <EnumFieldFilter
          onChange={value => updateFilters('license', value)}
          selected={selected?.license || ''}
          enumField={LicenseType}
          label="License"
          id="model-version-license"
          loading={loading}
        />
      )}
    </Box>
  );
};
