import { Theme, alpha, styled } from '@material-ui/core';

export const Text = styled('span')((props: { theme: Theme }) => ({
  marginRight: '10px',
  verticalAlign: 'middle',
  fontSize: '.8rem',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  flexShrink: 0,
  lineHeight: '20px',
  letterSpacing: '.2px',

  ['&, &:visited']: {
    color: props.theme.palette.text.secondary,
  },

  ['&:hover, &:active']: {
    color: props.theme.palette.link,
  },

  ['& svg']: {
    fontSize: 11,
    verticalAlign: 'middle',
    marginRight: '2px',
    marginBottom: '2px',
  },
}));

export const TextLabeled = styled(Text)((props: { theme: Theme }) => ({
  display: 'inline-block',
  flexShrink: 2,
  background: alpha(props.theme.palette.text.primary, 0.04),
  padding: '0 4px',
  borderRadius: '4px',

  ['&, &:visited, & svg']: {
    color: props.theme.palette.textSubtle,
  },

  ['&:hover, &:active']: {
    color: props.theme.palette.link,
  },
}));

export const TagGroup = styled('span')({
  display: 'flex',
  width: '100%',
  alignItems: 'baseline',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',

  '& > *': {
    marginRight: '8px',
  },
});

export const fontSize = {
  tiny: '.75rem',
  small: '.8rem',
  default: '.85rem',
  medium: '1rem',
  large: '1.2rem',
  super: '1.5rem',
};

export const SpacedTagGroup = styled(TagGroup)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));
